import React, { useState } from "react"
import "./style.css"
import VerticalStoryScroller from "./StoryScroller/Vertical"
import HorizontalStoryScroller from "./StoryScroller/Horizontal"
import { Button, Col, Container, Label, Row } from "reactstrap"

const StoryScroller = ({
  texts,
  images,
  name,
  handleDownloadClick,
  showDownloadButton = true,
}) => {
  const [fontSize, setFontSize] = useState(16)
  const getLayout = () => {
    if (window.innerWidth < 768) {
      return "vertical"
    }
    return "horizontal"
  }
  const [layout, setLayout] = useState(getLayout())

  const handleLayoutChange = () => {
    setLayout(layout === "vertical" ? "horizontal" : "vertical")
  }

  return (
    <>
      <div className="story-scroller">
        {layout === "vertical" ? (
          <VerticalStoryScroller
            texts={texts}
            images={images}
            name={name}
            layout={layout}
            handleLayoutChange={handleLayoutChange}
            handleDownloadClick={handleDownloadClick}
            showDownloadButton={showDownloadButton}
            fontSize={fontSize}
            setFontSize={setFontSize}
          />
        ) : (
          <HorizontalStoryScroller
            texts={texts}
            images={images}
            name={name}
            layout={layout}
            handleLayoutChange={handleLayoutChange}
            handleDownloadClick={handleDownloadClick}
            showDownloadButton={showDownloadButton}
            fontSize={fontSize}
            setFontSize={setFontSize}
          />
        )}
      </div>
    </>
  )
}

export default StoryScroller
