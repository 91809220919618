import React, { useState } from "react"
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { data } from "../data"

const itemsPerPage = 4

const BlogGrid = () => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = currentPage * itemsPerPage

  // Get the items to display on the current page
  const itemsToDisplay = data.slice(startIndex, endIndex)

  const totalPages = Math.ceil(data.length / itemsPerPage)

  // Function to handle page change
  const handlePageChange = page => {
    setCurrentPage(page)
  }

  return (
    <React.Fragment>
      <Col xl={12} lg={12} className="my-5">
        <Card>
          <div>
            <Row className="justify-content-center">
              <Col xl={8}>
                <div>
                  <hr className="m-5" />

                  <Row>
                    {itemsToDisplay.map((item, index) => {
                      return (
                        <Col sm={6} key={index}>
                          <Card
                            className="my-3 p-3 rounded border border-2 rounded-4 "
                            onClick={() =>
                              navigate(`/blog-details/${item.scrollName}`)
                            }
                          >
                            <CardImg variant="top" src={item.thumbnailUrl} />
                            <CardBody>
                              <CardTitle className="fs-4 mb-4 text-dark">
                                <strong>{item.scrollName}</strong>
                              </CardTitle>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}
                  </Row>

                  <hr className="my-4" />

                  <div className="text-center">
                    <ul className="pagination justify-content-center pagination-rounded">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="mdi mdi-chevron-left"></i>
                        </Link>
                      </li>
                      {Array.from({ length: totalPages }).map((_, page) => (
                        <li
                          key={page}
                          className={`page-item ${
                            page + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => handlePageChange(page + 1)}
                          >
                            {page + 1}
                          </Link>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          to="#"
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default BlogGrid
