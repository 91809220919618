import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import "react-toastify/dist/ReactToastify.css"

// Import the data
import { data } from "./data"
import StoryScroller from "./StoryScroller"
import { toast, ToastContainer } from "react-toastify"

const Story = () => {
  const { id } = useParams()
  const selectedItem = data.find(item => item.scrollName === id)
  const [isLoading, setIsLoading] = React.useState(true)
  if (!selectedItem) {
    return <div>Item not found</div>
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])

  // Function to handle the download button click event
  const handleDownloadClick = () => {
    // Get the previously saved items from local storage (if any)
    const savedItems = JSON.parse(localStorage.getItem("savedItems")) || []

    // Check if the selected item is already saved
    const isAlreadySaved = savedItems.some(
      item => item.scrollName === selectedItem.scrollName
    )
    if (isAlreadySaved) {
      toast.info("Item already saved")
      return
    }

    // Add the selected item to the list of saved items
    savedItems.push(selectedItem)

    // Save the updated list to local storage
    localStorage.setItem("savedItems", JSON.stringify(savedItems))

    toast.success("Story saved successfully")
  }

  document.title = "Blog Details | PWA"

  return (
    <div
      style={{
        background: `url(${selectedItem.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <>
        <Row>
          <Col lg={12}>
            <div>
              <div className="row justify-content-center">
                <Col lg={10} xs={12}>
                  {isLoading && (
                    <div id="preloader">
                      <div id="status">
                        <div className="spinner-chase">
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                        </div>
                        <hr />
                      </div>
                      <p
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          height: "130vh",
                        }}
                      >
                        {id} getting ready...
                      </p>
                    </div>
                  )}
                  <StoryScroller
                    texts={selectedItem.text}
                    images={selectedItem.images}
                    name={selectedItem.scrollName}
                    handleDownloadClick={handleDownloadClick}
                  />
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </>
      <ToastContainer />
    </div>
  )
}

export default Story
