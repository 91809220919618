import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Progress, Button, Label, Container, Row, Col } from "reactstrap"
import debounce from "lodash/debounce" // Import debounce from lodash
import { useInView } from "react-intersection-observer"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css" // Import InputRange styles

const VerticalStoryScroller = ({
  texts,
  images,
  name,
  layout,
  handleLayoutChange,
  handleDownloadClick,
  showDownloadButton,
  fontSize,
  setFontSize,
}) => {
  const intersectionRefs = texts.map(() =>
    useInView({
      threshold: 0,
      rootMargin: "0px 0px 0px 0px",
    })
  )
  const isPortrait = window.matchMedia("(orientation: portrait)").matches

  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [containerDimensions, setContainerDimensions] = useState({
    width: "100%",
    height: "70vh",
  })
  const [imageOpacity, setImageOpacity] = useState(1)
  const [toolbarVisible, setToolbarVisible] = useState(
    isPortrait ? true : false
  )
  const paragraphContainerRef = useRef(null)
  const imageContainerRef = useRef(null)
  const previousLargeImageHeight = useRef(0)

  // useEffect(() => {
  //   const handleWheel = debounce(e => {
  //     if (paragraphContainerRef.current) {
  //       const paragraphContainer = paragraphContainerRef.current
  //       const { scrollTop, scrollHeight, clientHeight } = paragraphContainer

  //       if (e.deltaY > 0 && currentIndex < texts.length - 1) {
  //         // Check if scrolling has reached the end of the text container
  //         if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
  //           incrementCurrentIndex()
  //         }
  //       } else if (e.deltaY < 0 && currentIndex > 0) {
  //         // Check if scrolling has reached the beginning of the text container
  //         if (scrollTop === 0) {
  //           decrementCurrentIndex()
  //         }
  //       }
  //     }
  //   }, 300) // Adjust the debounce delay as needed

  //   const handleImageContainerWheel = debounce(e => {
  //     if (imageContainerRef.current) {
  //       if (e.deltaY > 0 && currentIndex < texts.length - 1) {
  //         incrementCurrentIndex()
  //       } else if (e.deltaY < 0 && currentIndex > 0) {
  //         decrementCurrentIndex()
  //       }
  //       e.preventDefault()
  //       e.stopPropagation()
  //     }
  //   }, 300) // Adjust the debounce delay as needed

  //   const incrementCurrentIndex = () => {
  //     setCurrentIndex(prevIndex => {
  //       if (prevIndex < texts.length - 1) {
  //         return prevIndex + 1
  //       }
  //       return prevIndex
  //     })
  //     setImageOpacity(0)
  //   }

  //   const decrementCurrentIndex = () => {
  //     setCurrentIndex(prevIndex => {
  //       if (prevIndex > 0) {
  //         return prevIndex - 1
  //       }
  //       return prevIndex
  //     })
  //     setImageOpacity(0)
  //   }

  //   if (paragraphContainerRef.current) {
  //     paragraphContainerRef.current.addEventListener("wheel", handleWheel)
  //   }

  //   if (imageContainerRef.current) {
  //     imageContainerRef.current.addEventListener(
  //       "wheel",
  //       handleImageContainerWheel
  //     )
  //   }

  //   return () => {
  //     if (paragraphContainerRef.current) {
  //       paragraphContainerRef.current.removeEventListener("wheel", handleWheel)
  //     }

  //     if (imageContainerRef.current) {
  //       imageContainerRef.current.removeEventListener(
  //         "wheel",
  //         handleImageContainerWheel
  //       )
  //     }
  //   }
  // }, [currentIndex, texts])

  // ======================================================

  // useEffect(() => {
  //   const handleWheel = debounce((e, index) => {
  //     const paragraphContainer = paragraphRefs[index].current
  //     if (paragraphContainer) {
  //       const { scrollTop, scrollHeight, clientHeight } = paragraphContainer
  //       setCurrentIndex(index)
  //     }
  //   }, 0) // Adjust the debounce delay as needed

  //   paragraphRefs.forEach((ref, index) => {
  //     if (ref.current) {
  //       const paragraphContainer = ref.current
  //       const { scrollTop, scrollHeight, clientHeight } = paragraphContainer

  //       paragraphContainer.addEventListener("wheel", e => handleWheel(e, index))
  //     }
  //   })
  // }, [paragraphRefs, texts])

  // =======================================================
  useEffect(() => {
    const activeParagraph = Math.min(
      ...intersectionRefs
        .map((intersectionRef, index) => {
          if (intersectionRef.inView) {
            return index
          }
          return null
        })
        .filter(item => item !== null)
    )
    setCurrentIndex(activeParagraph)
  }, [intersectionRefs])

  useEffect(() => {
    const calculateContainerDimensions = () => {
      const screenAspectRatio = window.innerWidth / window.innerHeight
      const maxUpperSectionSize = "70vh"

      setContainerDimensions({
        width: "100%",
        height: maxUpperSectionSize,
      })
    }

    calculateContainerDimensions()
  }, [])

  const currentText = texts[currentIndex]
  const currentImage = images[currentIndex]

  const handleImageLoad = () => {
    setImageOpacity(1)
    if (imageContainerRef.current && currentImage) {
      previousLargeImageHeight.current = imageContainerRef.current.clientHeight
    }
  }

  const toggleToolbar = () => {
    setToolbarVisible(!toolbarVisible)
  }

  const handleFontSizeChange = increment => {
    setFontSize(prevFontSize => prevFontSize + increment)
  }

  // Check mode is portrait or landscape

  let imgStyles = {}
  let imgContainerStyles = {}
  let textContainerStyles = {}

  if (window.innerWidth < 768) {
    imgContainerStyles = {
      width: containerDimensions.width,
      height: currentImage ? "" : previousLargeImageHeight.current + "px",
      height: window.innerWidth < 768 ? (isPortrait ? "35vh" : "70vh") : "",
      maxHeight: window.innerWidth < 768 ? (isPortrait ? "35vh" : "70vh") : "",
      maxWidth: "100%", // Set the maximum width to fit the viewport
      overflow: "hidden",
      position: "relative",
    }
    imgStyles = {
      width: "100%",
      opacity: imageOpacity,
      transition: "opacity 0.5s",
      maxWidth: "100%", // Set the maximum width to fit the viewport
      height: "-webkit-fill-available",
      objectFit: isPortrait ? "cover" : "contain",
      objectPosition: "top",
    }

    textContainerStyles = {
      overflowY: "auto",
      // Set maximum height to remaining screen height from the viewport
      maxHeight: window.innerWidth < 768 ? "65vh" : "",
      // maxHeight: window.innerHeight - previousLargeImageHeight.current,
    }
  } else {
    // ===============================================
    imgContainerStyles = {
      width: "100%",
      height: "65vh",
      maxHeight: "65vh",

      maxWidth: "100%", // Set the maximum width to fit the viewport
    }
    imgStyles = {
      width: "100%",
      height: "100%",

      objectFit: "contain",
      opacity: imageOpacity,
      transition: "opacity 0.5s",
      maxWidth: "100%", // Set the maximum width to fit the viewport
    }

    textContainerStyles = {
      overflowY: "auto",
      // Set maximum height to remaining screen height from the viewport
      maxHeight: "33vh",
    }
    // ===============================================
  }

  const handleSliderChange = value => {
    const doc = document.getElementById(`p-v-${value - 1}`)
    if (doc) {
      doc.scrollIntoView({
        behavior: "smooth",
      })
      setTimeout(() => {
        setCurrentIndex(value - 1)
      }, 0)
    }
  }

  return (
    <>
      <div className="story-scroller">
        <div
          className="scroll-container"
          style={{
            flexDirection: "column",
          }}
        >
          <div
            className="image-container pb-3"
            ref={imageContainerRef}
            style={imgContainerStyles}
          >
            {currentImage && (
              <img
                src={currentImage}
                style={imgStyles}
                onLoad={handleImageLoad}
              />
            )}
          </div>
          <div
            className="text-container"
            // ref={paragraphContainerRef}
            style={textContainerStyles}
            // onClick={() => setCurrentIndex(index)}
          >
            {texts.map((text, index) => {
              return (
                <p
                  key={index}
                  id={`p-v-${index}`}
                  ref={intersectionRefs[index].ref}
                  style={{
                    fontSize: `${fontSize}px`,
                    marginBottom: index === texts.length - 1 ? "50px" : "5px",
                  }}
                >
                  {text}
                </p>
              )
            })}
          </div>
        </div>

        <Button
          size="lg"
          style={{
            backgroundColor: "#f2f2f5",
            borderColor: "#f2f2f5",
            color: "#555b6d",
            fontWeight: "bold",
            position: "fixed",
            bottom: toolbarVisible
              ? window.innerWidth > 768
                ? "118px"
                : !isPortrait
                ? "118px"
                : "180px"
              : "0",
            right: "0",
            borderRadius: "0",
          }}
          className="toggle-toolbar me-auto"
          onClick={toggleToolbar}
        >
          {toolbarVisible ? (
            <i className="mdi mdi-close" />
          ) : (
            <i className="mdi mdi-menu" />
          )}
        </Button>
      </div>
      {toolbarVisible && (
        <footer
          className="footer position-fixed"
          style={{
            height:
              window.innerWidth > 768
                ? "130px"
                : !isPortrait
                ? "130px"
                : "180px",
          }}
        >
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="toolbar">
                  <h5 className="scroll-name">{name}</h5>
                  <div className="d-flex justify-content-between flex-wrap">
                    <Button
                      onClick={handleLayoutChange}
                      className="mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                    >
                      <i className="mdi mdi-view-quilt me-1" />
                      Vertical
                    </Button>

                    <Button
                      onClick={() => navigate("/downloads")}
                      className="mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                    >
                      Offline library
                    </Button>
                    {showDownloadButton && (
                      <Button
                        onClick={handleDownloadClick}
                        className="mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                      >
                        <i className="fa fa-download me-1" />
                        Download
                      </Button>
                    )}
                    <div className="d-flex align-items-center justify-content-between mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                      <Label className="me-2">
                        Font Size : <i className="fa fa-text-height" />
                      </Label>
                      <Button
                        color="success"
                        onClick={() => handleFontSizeChange(2)}
                        className="me-2"
                      >
                        <i className="fa fa-plus" />
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => handleFontSizeChange(-2)}
                      >
                        <i className="fa fa-minus" />
                      </Button>
                    </div>
                  </div>
                  <div className="toolbar-top">
                    <div className="progress-bar pt-4 ">
                      <InputRange
                        maxValue={texts.length}
                        minValue={1}
                        onChange={handleSliderChange}
                        value={currentIndex + 1}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </>
  )
}

export default VerticalStoryScroller
