import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import BlogList from "pages/Blog/download/Downloads"
import BlogGrid from "pages/Blog/BlogGrid/BlogGrid"
import Downloads from "pages/Blog/download/Downloads"
import SavedStory from "pages/Blog/SavedStory/SavedStory"
import Story from "pages/Blog/Story"

const authProtectedRoutes = [
  //Blog
  { path: "/downloads", component: <Downloads /> },
  { path: "/saved/:id", component: <SavedStory /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-details/:id", component: <Story /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/downloads" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
