import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Card, CardBody, Col, Row } from "reactstrap"
import StoryScroller from "../StoryScroller"
import { ToastContainer } from "react-toastify"

const SavedStory = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [])
  // Retrieve saved items from local storage
  const savedItems = JSON.parse(localStorage.getItem("savedItems")) || []
  console.log(savedItems)
  const selectedItem = savedItems.find(item => item.scrollName === id)
  console.log(selectedItem)
  return (
    <div
      style={{
        background: `url(${selectedItem.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <>
        <Row>
          <Col lg={12}>
            <div className="pt-3">
              <div className="row justify-content-center">
                <Col lg={10} xs={12}>
                  {isLoading && (
                    <div id="preloader">
                      <div id="status">
                        <div className="spinner-chase">
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                          <div className="chase-dot" />
                        </div>
                        <hr />
                      </div>
                      <p
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          height: "130vh",
                        }}
                      >
                        {id} getting ready...
                      </p>
                    </div>
                  )}
                  <StoryScroller
                    texts={selectedItem.text}
                    images={selectedItem.images}
                    name={selectedItem.scrollName}
                    showDownloadButton={false}
                  />
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </>
      <ToastContainer />
    </div>
  )
}

export default SavedStory
