import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Label, Container, Row, Col } from "reactstrap"
import { useInView } from "react-intersection-observer"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css" // Import InputRange styles

const HorizontalStoryScroller = ({
  texts,
  images,
  name,
  handleLayoutChange,
  handleDownloadClick,
  showDownloadButton,
  fontSize,
  setFontSize,
}) => {
  const navigate = useNavigate()
  const isPortrait = window.matchMedia("(orientation: portrait)").matches
  const intersectionRefs = texts.map(() =>
    useInView({
      threshold: !isPortrait ? 0 : 0.3,
    })
  )
  const [currentIndex, setCurrentIndex] = useState(0)

  const [containerDimensions, setContainerDimensions] = useState({
    width: "70vw",
    height: "100vh",
  })
  const [imageOpacity, setImageOpacity] = useState(1)
  const [toolbarVisible, setToolbarVisible] = useState(true)
  const imageContainerRef = useRef(null)

  useEffect(() => {
    const activeParagraph = Math.min(
      ...intersectionRefs
        .map((intersectionRef, index) => {
          if (intersectionRef.inView) {
            return index
          }
          return null
        })
        .filter(item => item !== null)
    )
    setCurrentIndex(activeParagraph)
  }, [intersectionRefs])

  useEffect(() => {
    const calculateContainerDimensions = () => {
      const maxUpperSectionSize = "70vw"

      setContainerDimensions({
        width: maxUpperSectionSize,
        height: "100%",
      })
    }

    calculateContainerDimensions()
  }, [])

  const currentText = texts[currentIndex]
  const currentImage = images[currentIndex]

  const handleImageLoad = () => {
    setImageOpacity(1)
  }

  const toggleToolbar = () => {
    setToolbarVisible(!toolbarVisible)
  }

  const handleFontSizeChange = increment => {
    setFontSize(prevFontSize => prevFontSize + increment)
  }

  const handleSliderChange = value => {
    const doc = document.getElementById(`p-h-${value - 1}`)
    if (doc) {
      doc.scrollIntoView({
        behavior: "smooth",
      })
      setTimeout(() => {
        setCurrentIndex(value - 1)
      }, 0)
    }
  }

  return (
    <>
      <div className="story-scroller">
        <div
          className="scroll-container"
          style={{
            display: "flex",
            flexDirection: "row",
            maxHeight: "100vh",
          }}
        >
          <div
            className="image-container p-3"
            ref={imageContainerRef}
            style={{
              width: containerDimensions.width,
              height: containerDimensions.height,
            }}
          >
            {currentImage && (
              <img
                src={currentImage}
                alt={currentText}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  opacity: imageOpacity,
                  transition: "opacity 0.5s",
                }}
                onLoad={handleImageLoad}
              />
            )}
          </div>
          <div
            className="text-container m-3"
            // ref={paragraphContainerRef}
            style={{
              width: "30vw",
              overflowY: "auto",
            }}
          >
            {texts.map((text, index) => {
              return (
                <p
                  key={index}
                  id={`p-h-${index}`}
                  ref={intersectionRefs[index].ref}
                  style={{
                    fontSize: `${fontSize}px`,
                    marginBottom: "5px",
                    borderBottom: "0.2px solid #0000001c",
                  }}
                >
                  {text}
                </p>
              )
            })}
          </div>
        </div>

        <Button
          size="lg"
          style={{
            backgroundColor: "#f2f2f5",
            borderColor: "#f2f2f5",
            color: "#555b6d",
            fontWeight: "bold",
            position: "fixed", // Set to fixed position
            bottom: toolbarVisible
              ? window.innerWidth > 768
                ? "118px"
                : "180px"
              : "0", // Adjust the position as needed
            right: "0", // Adjust the position as needed
            borderRadius: "0",
          }}
          className="toggle-toolbar me-auto"
          onClick={toggleToolbar}
        >
          {toolbarVisible ? (
            <i className="mdi mdi-close" />
          ) : (
            <i className="mdi mdi-menu" />
          )}
        </Button>
      </div>
      {toolbarVisible && (
        <footer
          className="footer position-fixed"
          style={{ height: window.innerWidth > 768 ? "130px" : "180px" }}
        >
          <Container fluid={true}>
            <Row>
              <Col md={12}>
                <div className="toolbar">
                  <h5 className="scroll-name">{name}</h5>
                  <div className="d-flex justify-content-between flex-wrap">
                    <Button onClick={handleLayoutChange}>
                      <i className="mdi mdi-view-quilt me-1" />
                      Horizontal
                    </Button>

                    <Button onClick={() => navigate("/downloads")}>
                      Offline library
                    </Button>
                    {showDownloadButton && (
                      <Button onClick={handleDownloadClick}>
                        <i className="fa fa-download me-1" />
                        Download
                      </Button>
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                      <Label className="me-2">
                        Font Size : <i className="fa fa-text-height" />
                      </Label>
                      <Button
                        color="success"
                        onClick={() => handleFontSizeChange(2)}
                        className="me-2"
                      >
                        <i className="fa fa-plus" />
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => handleFontSizeChange(-2)}
                      >
                        <i className="fa fa-minus" />
                      </Button>
                    </div>
                  </div>
                  <div className="toolbar-top">
                    <div className="progress-bar pt-4">
                      <InputRange
                        maxValue={texts.length}
                        minValue={1}
                        onChange={handleSliderChange}
                        value={currentIndex + 1}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </>
  )
}

export default HorizontalStoryScroller
